@import '../../assets/scss/variables';

#footer
{
	border-top: 3px solid #52BE80;
}

.logo
{
	height: auto;
	width:220px;
}

.copyrightRow
{
	border-top: 1px solid #52BE80;
}

.devlink
{
	color:white;
}