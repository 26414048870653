@import './variables';

.nav-link:hover, .active
{
	color: #3EA469 !important;
}

.btn-green
{
	background-color: #52BE80 !important;
	color: white !important;
}

.btn-green:hover, .btn-green:active, .btn-green:focus
{
	background-color: #3EA469;
	color: white;
}

.btn-green:active, .btn-green:focus
{
	box-shadow: 0 0 10px 1px #3EA469;
}

.glow
{
	box-shadow: 0 0 10px 2px #3EA469;
}

.glow-text
{
	text-shadow: 0 0 10px #3EA469;
}

.glow-text > svg
{
	filter: drop-shadow(0px 0px 7px rgb(62, 164, 105));
}