@import '../../assets/scss/variables';

#mainBanner
{
	border-bottom: 7px solid #52BE80;
}

.DiscordIcon, .MessengerIcon, .WhatsappIcon, .TelegramIcon
{
	color: #52BE80;
	font-size: 2rem;
	padding-top: 0.5rem;
	transition: all 400ms ease;
	margin-bottom:0.5rem;

	&:hover
	{
		font-size:2.5rem;
		margin-bottom:0;
	}
}

.DiscordIcon
{
	color: #7289da;
}

.MessengerIcon
{
	color: #00B2FF;
}

.WhatsappIcon
{
	color: #25D366;
}

.TelegramIcon
{
	color: #2AABEE;
}